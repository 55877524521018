// src/serviceWorkerRegistration.ts

/* eslint-disable no-console */

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] est l'IPv6 localhost
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8
    window.location.hostname.match(/^127(?:\.[0-9]+){0,2}\.[0-9]+$/)
);

type Config = {
    onUpdate?: (registration: ServiceWorkerRegistration) => void;
    onSuccess?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
    if ("serviceWorker" in navigator) {
        const publicUrl = new URL(process.env.PUBLIC_URL || "", window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            // Si l'origine diffère, on ne tente pas d'enregistrer le SW
            return;
        }

        window.addEventListener("load", () => {
            const swUrl = `${process.env.PUBLIC_URL || ""}/service-worker.js`;

            if (isLocalhost) {
                // En mode développement : vérification et enregistrement du SW
                checkValidServiceWorker(swUrl, config);
                navigator.serviceWorker.ready.then(() => {
                    console.log("Service Worker is ready (localhost).");
                });
            } else {
                // En production, enregistrement classique du SW
                registerValidSW(swUrl, config);
            }
        });
    }
}

function registerValidSW(swUrl: string, config?: Config) {
    navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
            console.log("Service Worker registered with scope:", registration.scope);
            if (config && config.onSuccess) {
                config.onSuccess(registration);
            }
            // Ce SW est spécialement configuré pour mettre en cache les manifestes et segments HLS,
            // améliorant ainsi la fluidité en conditions de réseau faible.
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === "installed") {
                        if (navigator.serviceWorker.controller) {
                            // Nouvelle version disponible
                            console.log("New content is available; please refresh.");
                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        } else {
                            // Contenu mis en cache pour une utilisation hors ligne.
                            console.log("Content is cached for offline use.");
                        }
                    }
                };
            };
        })
        .catch((error) => {
            console.error("Error during service worker registration:", error);
        });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
    // Vérifie si le service worker existe réellement sur le serveur.
    fetch(swUrl, {
        headers: { "Service-Worker": "script" },
    })
        .then((response) => {
            const contentType = response.headers.get("content-type");
            if (
                response.status === 404 ||
                (contentType != null && contentType.indexOf("javascript") === -1)
            ) {
                // Aucun SW trouvé. On désenregistre le SW existant et recharge la page.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Le SW existe, on l'enregistre.
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            console.log("No internet connection. App is running in offline mode.");
        });
}

export function unregister() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
}
