import { api } from './client';
import dayjs, { Dayjs } from 'dayjs';
import {
    CryptoInfo,
    Order,
    Post,
    PriceHistory,
    ProductInfo,
    ProductResult,
    Role,
    User,
    GetPostsParams,
    GetUserProfileResponse,
    Comment,
    RoiHistory,
    GetUserStatsResponse,
    Analysis,
    UserOrigin,
    Like,
    Reply,
    SubscriptionResponse,
    SubscriptionStatus,
    MonthlyRevenue,
    PayoutData,
    AdminData,
    Connection,
    Payments,
    Transfer,
    PromoCodeData,
    ProductCategory,
    Language,
    SubscriptionFormula, NotificationType, NotificationsData, SaveReplyResponse, Card
} from '@/types/types';
import { objectKeysToParamList } from '@/utils/post';
import { wait } from '@testing-library/user-event/dist/utils';
import { AxiosResponse } from 'axios';

export interface CreateUserParams {
    role: Role;
    origin: UserOrigin;
    email?: string;
    password?: string;
    pseudo?: string;
    firstName?: string;
    lastName?: string;
    language?: Language;
    mentorPrice?: string;
    subscriptionFormula?: SubscriptionFormula;
}

// Création d'utilisateur (backend décode le user depuis le token)
export const createUser = (user: CreateUserParams) => {
    return api.post<User>('/users', user);
};

// Récupération de l'utilisateur connecté (décodé depuis le token)
export const getUser = () => {
    return api.get<User>('/users');
};

// Mise à jour de l'utilisateur
export const updateUser = (user: Partial<User>) => {
    return api.put<User>('/users', user);
};

export const listPaymentMethods = () => {
    return api.get<Card[]>('/users/payments');
};

export const replacePaymentMethodApi = (payload: {
    oldPaymentMethodId: string;
    newPaymentMethodId: string;
}) => {
    return api.put<Card>('/users/payments', payload);
};

// Création d'un post
export const createPost = (post: Partial<Post>) => {
    return api.post<{ post: Post; user: User }>('/posts', post);
};

// Récupération des posts d'un utilisateur
export const getUserPosts = (
    {
        userId,
        limit = 10,
        cursor,
        options,
        orderStatus,
        orderBy,
    }: GetPostsParams = { userId: '' }
) => {
    return api.get<Post[]>(`/users/${userId}/posts`, {
        params: {
            limit,
            cursor,
            options: objectKeysToParamList({ ...options }),
            orderStatus,
            orderBy,
        },
    });
};

// Recherche de produits (assets)
export const searchProducts = (
    text: string,
    limit: number = 10,
    exchange: string = ''
) => {
    return api.get<ProductResult[]>(
        `/search/products?query=${text}&limit=${limit}&exchange=${exchange}${
            exchange === ProductCategory.CRYPTO ? '&cryptoVersion=2' : ''
        }`
    );
};

// Infos d'un produit spécifique
export const getProductInfo = ({
                                   ticker,
                                   exchange,
                                   includeMarketStatus = false,
                               }: {
    ticker: string;
    exchange: string;
    includeMarketStatus?: boolean;
}) => {
    return api.get<(ProductInfo & CryptoInfo)[]>(`/products/${ticker}`, {
        params: {
            exchange,
            ...(includeMarketStatus && { options: 'includeMarketStatus' }),
        },
    });
};

// Récupération du capital temps réel d'un user Trader
export const getRealTimeCapital = () => {
    return api.get<{ capital: number }>('/users/capital');
};

// Historique des prix d'un produit
export const getProductHistory = (
    ticker: string,
    from: Date | Dayjs,
    to: Date | Dayjs
) => {
    return api.get<PriceHistory>(`/products/history/${ticker}`, {
        params: {
            from: dayjs(from).format('YYYY-MM-DD'),
            to: dayjs(to).format('YYYY-MM-DD'),
        },
    });
};

// Fermer un ordre (closeOrder)
export const closeOrder = (id: string, analysis?: Partial<Analysis>) => {
    return api.patch<any>(`/orders/${id}`, {
        ...analysis,
    });
};

// Mettre à jour un ordre (threshold, stop, take profit, etc.)
export const updateThreshold = (id: string, threshold?: Partial<Order>) => {
    return api.patch<any>(`/orders/threshold/${id}`, {
        ...threshold,
    });
};

// Récupérer un ordre
export const getOrder = (id: string) => {
    return api.get<{ order: Order }>(`/orders/${id}`);
};


/**
 * On appelle l’endpoint PUT /posts
 * avec un body contenant : { postId, analysis, order, ... }
 */
export const updatePost = (data: Partial<Post> & { postId: string }) => {
    return api.put<{ updatedPost: Post }>('/posts', data);
};

// Supprimer un post
export const deletePost = (id: number | string) => {
    return api.delete<any>(`/posts/${id}`);
};

// Récupérer des traders
export type GetTraderParams = {
    limit?: number;
    cursor?: string;
    options?: string;
    language?: Language;
};

export const getTraders = ({
                               limit,
                               cursor,
                               options,
                               language,
                           }: GetTraderParams = {}) => {
    return api.get<User[]>('users/traders', {
        params: {
            limit,
            cursor,
            options,
            language,
        },
    });
};

// Récupérer le ROI d'un user
export const getUserRoi = (userId: string) => {
    return api.get<{ roi: number }>(`users/roi/${userId}`);
};

export interface GetTradersPostsOptions {
    onlyFavorites?: boolean;
}

export interface GetTradersPostsParams {
    limit?: number;
    cursor?: string;
    options?: GetTradersPostsOptions;
    language?: Language;
}

// Récupérer les posts des traders avec filtrage
export const getTradersPosts = ({
                                    limit = 10,
                                    cursor,
                                    options,
                                    language,
                                }: GetTradersPostsParams = {}) => {
    return api.get<Post[]>('/posts/traders', {
        params: {
            limit,
            cursor,
            options: objectKeysToParamList({ ...options }),
            language,
        },
    });
};

// Profil d'un utilisateur par son id
export const getUserProfile = (userId: string) => {
    return api.get<GetUserProfileResponse>(`/users/profile/${userId}`);
};

// Suivre un trader
export const followTrader = (traderId: string) => {
    return api.put(`/users/traders/${traderId}`);
};

// Ne plus suivre un trader
export const unfollowTrader = (traderId: string) => {
    return api.delete(`/users/traders/${traderId}`);
};

// Sauvegarder un commentaire
export const saveComment = (postId: string, comment: string, imageUrl: string | null) => {
    return api.post<Comment[]>(`/posts/${postId}/comments`, { comment, imageUrl });
};

export const updateComment = (commentId: number, comment: string, imageUrl: string | null) => {
    return api.put<Comment>(`/comment`, {commentId, comment, imageUrl });
}

// Récupérer les commentaires d'un post
export const getPostComments = (postId: string) => {
    return api.get<Comment[]>(`/posts/${postId}/comments`);
};

// Sauvegarder une réponse à un commentaire
export const saveReply = (commentId: number, reply: string, imageUrl: string | null) => {
    return api.post<SaveReplyResponse>(`/comments/${commentId}/replies`, { reply, imageUrl });
};

export const updateReply = (replyId: number, reply: string, imageUrl: string | null) => {
    return api.put<Reply>(`/reply`, {replyId, reply, imageUrl });
}

// Récupérer les réponses d'un commentaire
export const getReplies = (commentId: number) => {
    return api.get<Reply[]>(`/comments/${commentId}/replies`);
};

// Liker un post
export const saveLike = (postId: string) => {
    return api.post(`/posts/${postId}/likes`);
};

// Unliker un post
export const deleteLike = (postId: string) => {
    return api.delete(`/posts/${postId}/likes`);
};

// Récupérer les likes d'un post
export const getLikes = (postId: string) => {
    return api.get<Like[]>(`/posts/${postId}/likes`);
};

// Récupérer l'historique du ROI d'un user
export const getRoiHistory = (userId: string, from: Date) => {
    return api.get<RoiHistory[]>(`/users/roi/${userId}/history`, {
        params: {
            from,
        },
    });
};

export interface GetUserStatsOptions {
    breakdown?: boolean;
    financial?: boolean;
    postsTypesCount?: boolean;
}

// Récupérer les stats d'un utilisateur
export const getUserStats = (userId: string, options: GetUserStatsOptions) => {
    return api.get<GetUserStatsResponse>(`/users/${userId}/stats`, {
        params: {
            options: objectKeysToParamList({ ...options }),
        },
    });
};

// Marquer un post en favori
export const saveFavoritePost = (postId: string) => {
    return api.post(`/posts/${postId}/favorite`);
};

// Retirer un post des favoris
export const deleteFavoritePost = (postId: string) => {
    return api.delete(`/posts/${postId}/favorite`);
};

// Obtenir les posts favoris de l'utilisateur
export const getFavoritePosts = () => {
    return api.get<Post[]>('/users/favoritePosts');
};

// Récupérer un post par son id
export const getPost = (postId: string) => {
    return api.get<Post>(`/posts/${postId}`);
};

// Créer un client Stripe (abonnement)
export const createStripeCustomer = () => {
    return api.post<SubscriptionResponse>('/subscriptions/');
};

// Annuler un abonnement
export const cancelSubscription = (traderId: string) => {
    return api.delete(`/subscriptions/${traderId}/`);
};

// Récupérer le statut de la connexion (abonnement) à un trader
export const getConnectionStatus = (traderId: string) => {
    return api.get<{ status: SubscriptionStatus }>(`/subscriptions/${traderId}/status`);
};

// Checker jusqu'à ce que l'abonnement soit actif ou dépasser le temps max
export const checkForSubscriptionActiveStatus = (traderId: string) => {
    const MAX_RETRIES = 40;
    const MAX_DURATION = 40 * 1000; // 40s

    let retriesCount = 0;
    let startTime = Date.now();

    return new Promise((resolve, reject) => {
        const getStatus = async () => {
            try {
                const duration = Date.now() - startTime;
                retriesCount++;

                const { data } = await getConnectionStatus(traderId);

                if (retriesCount > MAX_RETRIES || duration > MAX_DURATION) {
                    resolve(data.status);
                    return;
                }

                if (data.status === SubscriptionStatus.ACTIVE) {
                    resolve(data.status);
                    return;
                }

                await wait(1000);
                getStatus();
            } catch (error) {
                reject(error);
            }
        };
        getStatus();
    });
};

// Recherche de traders par un string
export const searchTraders = (string: string) => {
    return api.get<User[]>(`/search/${string}/traders`);
};

// Récupérer le revenu mensuel
export const getMonthlyRevenue = () => {
    return api.get<MonthlyRevenue[]>(`/users/traders/revenue`);
};

// Téléchargement de fichier
export const getFile = (fileUrl: string, filename: string) => {
    return api.post(
        `/files/`,
        { fileUrl, filename },
        {
            responseType: 'blob',
        }
    );
};

// Suppression d'un fichier
export const removeFile = (filename: string) => {
    return api.delete(`/files/${filename}`);
};

// Vérifier si un pseudo est pris
export const checkPseudo = (pseudo: string) => {
    return api.put<{ pseudoTaken: boolean }>(`/users/pseudo/${pseudo}`);
};

// Créer un compte connecté (Stripe)
export const createConnectedAccount = (account_token: string, country_code: string) => {
    return api.post<any>('/payments/account', { account_token, country_code });
};

// Obtenir le lien d'onboarding Stripe
export const getStripeOnboardingLink = () => {
    return api.get<{ url: string }>('/payments/account/link/onboarding');
};

// Obtenir le lien d'update Stripe
export const getStripeUpdateLink = () => {
    return api.get<{ url: string }>('/payments/account/link/update');
};

// Obtenir les données de paiement (payout)
export const getPayoutData = () => {
    return api.get<PayoutData>('/payments/account');
};

// Envoyer un message (contact)
export const sendMessage = (message: string) => {
    return api.post<any>('/contact', { message });
};

// Données admin
export const getAdminData = () => {
    return api.get<AdminData>('/admin/stats');
};

type GetNotificationsFn = (
    userId: string,
    cursor?: number,
    limit?: number,
    types?: NotificationType[]
) => Promise<AxiosResponse<NotificationsData>>;

// Récupérer les notifications
export const getNotifications: GetNotificationsFn = (
    userId,
    cursor,
    limit,
    types
) => {
    const params: Record<string, any> = {
        userId,
        cursor,
        limit,
    };

    if (types && types.length > 0) {
        params.types = types.join(',');
    }

    return api.get<NotificationsData>('/notifications', { params });
};

// Marquer les notifications de post comme "clickées"
export const markPostNotifsAsClicked = (postId: string) => {
    return api.put(`/notifications/click/${postId}`);
};

export const markCommentNotifsAsClicked = (commentId: number) => {
    return api.put(`/notifications/click/comment/${commentId}`);
};

export const markReplyNotifsAsClicked = (replyId: number) => {
    return api.put(`/notifications/click/reply/${replyId}`);
};

// Marquer les notifications comme vues
export const markNotifsAsSeen = () => {
    return api.put('/notifications/seen');
};

// Marquer la notif d'un nouvel abonné comme "clickée"
export const markNewSubscriberNotifAsClicked = (userId: string) => {
    return api.put(`/notifications/click/newSubscription/${userId}`);
};

// Obtenir les subscriptions actives (mentors auxquels l'utilisateur est abonné)
export const getSubscriptions = (limit: number = 3, cursor?: number) => {
    return api.get<Connection[]>(
        `/users/mentors/subscriptions?limit=${limit}&cursor=${cursor || ''}`
    );
};

// Obtenir les paiements reçus par un mentor
export const getPayments = (limit: number = 3, cursor?: number) => {
    return api.get<Payments[]>(
        `/users/mentors/payments?limit=${limit}&cursor=${cursor || ''}`
    );
};

// Obtenir les transferts
export const getTransfers = () => {
    return api.get<Transfer[]>('/users/mentors/transfers');
};

// Lancer un script admin
export const runScript = () => {
    return api.put('/admin/script');
};

// Vérifier un code promo
export const checkPromoCode = (code: string, traderId: string) => {
    return api.put<PromoCodeData>(
        `/payments/promocode/${code}/mentor/${traderId}`
    );
};

// Status du marché (ouvert/fermé)
export const getMarketStatus = (code: string) => {
    return api.get<{ isOpen: Boolean }>(`/markets/${code}/status`);
};

// Obtenir un checkout subscription (Stripe)
export const getCheckoutSubscription = (
    traderId: string,
    customerId: string,
    price: string,
    commitment: string
) => {
    return api.post<string>(`/subscriptions/checkout/${traderId}`, {
        customerId,
        price,
        commitment,
    });
};

// Obtenir le prix d'un paiement (abonnement)
export const getPaymentPrice = (traderId: string) => {
    return api.get<{ price: string, commitment: number, counterMonth: number }>(`/subscriptions/${traderId}`);
};

export const reSubscription = (traderId: string) => {
    return api.put(`/subscriptions/${traderId}/`);
};

export const getMetadata = (data: {url: string}) => {
    return api.post('/scrap', data);
}

// Vous pouvez déclarer une interface pour le PlaybackId
export interface PlaybackId {
    id: string;
    policy: string; // ou éventuellement: 'public' | 'signed'
}

export interface UploadVideoResponse {
    asset: {
        id: string;
        status: string;
        playback_ids: PlaybackId[];
    };
}

export const uploadVideoToMux = (
    firebaseUrl: string
): Promise<UploadVideoResponse> => {
    console.log('uploadVideoToMux -> firebaseUrl =', firebaseUrl);

    return api
        .post<UploadVideoResponse>('/video/upload', {url: firebaseUrl})
        .then((response) => response.data);
};

export const getPlaybackId = async (AssetId: string): Promise<any | null> => {
    try {
        const response = await api.get<{ playbackId: string | null }>(`/video/playback/${AssetId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching playback:", error);
        return null;
    }
};